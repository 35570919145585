/*
 * 업무구분 : 고객관리
 * 화면 명 : MSPCM121M
 * 화면 설명: 고객알람(고객접촉정보)
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.01.30
 * 작 성 자: 서영화 
 */

<template>
<ur-page-container title="고객알람(고객접촉정보)" @on-header-left-click="fn_ClickBackBtn" @action-search-click="fn_OpenMSPCM122P" 
                   class="msp" :show-title="true" type="subpage" action-type="search"  :topButton="true">
    <template #frame-header-fixed>

      <!-- 검색결과 영역 start-->
      <ur-box-container  v-if="isSearched && !isCleared" alignV="start" direction="column" class="search-result-box bd-b-Ty1 bd-T-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box  default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <template v-for="item in searchedItems">
            <mo-tab-label v-if="!$bizUtil.isEmpty(item.text)" @click="fn_OpenMSPCM122P" :idx="item.idx" :key="item.idx" >
              <!-- 검색결과 조건이름 -->
              <span class="tit" v-if="!$bizUtil.isEmpty(item.title)">{{ item.title }}</span>
              <!-- 검색결과 조건값 -->
              <span class="txt">{{ item.text }}</span>
            </mo-tab-label>
          </template>
        </mo-tab-box>
      </ur-box-container>
      <!-- 검색결과 영역 end-->

      <!-- 데이터개수 및 체크박스 영역 start -->
      <ur-box-container alignV="start" componentid="ur-box-container_001" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isCheckAll" @click="fn_ClickCheckAll" :disabled="isDisabledCheck || isNoData" size="small"/>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <!-- 전체 데이터 개수  -->
              <span v-if="checkedCount <= 0" class="sum">총 <strong class="crTy-blue3">{{ totalCount }}</strong> 명</span>
              <!-- 선택된 데이터 개수 -->
              <span v-else class="sum"><strong class="crTy-blue3">{{ checkedCount }}</strong> 명 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- 데이터개수 및 체크박스 영역 end -->
    </template>

    <!-- Content영역 start -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isNoData"  alignV="start" componentid="ur-box-container_002" direction="column" class="ns-check-list type-2">
        <!-- 조회결과 리스트 -->
        <!-- ur-box-container class="pb60" 이 부분 추가 : 체크했을 때, bottomsheet로 데이터가 가려서 필요  -->
        <ur-box-container class="pb60"> 
          <mo-list :list-data="cmCustCntcInfoListSVO">
            <template #list-item="{ index, item }">
              <mo-list-item expandable prevent-expand class="pt5" :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
                <!-- 체크박스 -->
                <mo-checkbox v-model="item.checked" @input="fn_ClickCheckPartial" :disabled="isDisabledCheck" :ref="`chk${index}`"/>
                <div class="list-item__contents pb10">
                  <div class="list-item__contents__title" style="gap: 10px;">
                    <!-- 고객명 -->
                    <!-- 고객카드 없음 240129 추가 -->
                    <span class="name" style="display: flex;">
                      <span class="txtSkip" @click.stop="fn_OpenCustInfoBs(item)">
                        {{ item.custNm }}
                      </span>
                      <span :class="!item.chnlCustId.trim() ? 'need-cs-card' : null" />
                    </span>
                    <!-- 고객카드 없음 240129 추가 -->
                    <!-- 실시간여부 -->
                    <mo-badge v-if="item.onlBatUseScCd === 'O'" class="badge-sample-badge lightblue sm" text="실시간여부" shape="status">실시간</mo-badge> 
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 생년월일 -->
                    <span class="crTy-bk1">{{ item.ralDob }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 접촉내용 -->
                    <span class="crTy-bk1 underline" @click.stop="fn_OpenMSPCM126P(item)">{{ item.cntcCntnt }}</span>
                  </div>
                  <div class="list-item__contents__info">
                    <!-- 발생일 -->
                    <span class="crTy-bk7 fs14rem mr10">발생일</span><span class="crTy-bk1">{{ item.occuYmd }}</span>
                  </div>
                </div>
                <!-- 아코디언 컨텐츠 -->
                <template v-slot:expand>
                  <div class="list-item-detail">
                    <!-- 발생원천 -->
                    <div class="contents-row">
                      <span class="dsInline crTy-bk7 fs14rem mr10 min50">발생원천</span>
                      <span class="crTy-bk1">{{ item.cntcOrgNm }}</span>
                    </div>
                    <!-- 개봉일 -->
                    <div class="contents-row">
                      <span class="dsInline crTy-bk7 fs14rem mr10 min40">개봉일</span>
                      <span class="crTy-bk1">{{ item.rlsYmd }}</span>
                    </div>
                    <!-- 터치일 -->
                    <div class="contents-row">
                      <span class="dsInline crTy-bk7 fs14rem mr10 min40">터치일</span>
                      <span class="crTy-bk1">{{ item.rntTuchYmd }}</span>
                    </div>
                  </div>
                </template>
              </mo-list-item>
            </template>
          </mo-list>   
        </ur-box-container>
      </ur-box-container>

      <!-- NoData 영역 start -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_005" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역 end -->

    </ur-box-container>
    <!-- Content영역 end -->

  </ur-page-container>
</template>

<script>
import moment      from 'moment'
import Msg         from '@/systems/webkit/msg/msg'
import cmConstants from '@/config/constants/cmConstants'
import MSPCM122P   from '@/ui/cm/MSPCM122P'             // 고객알람(고객접촉정보) 고객검색
import MSPCM126P   from '@/ui/cm/MSPCM126P'             // 고객알람(고객접촉정보) 상세조회
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {

  name: 'MSPCM121M', 
  screenId: 'MSPCM121M', 
  mixins: [Screen],
  components: {   
    MSPCM122P,
    MSPCM126P,
  },//componets,

  props:{},//props

  data () {
    return {
      title: '고객알람(고객접촉정보)',

      lv_inputParam: {},                                            // 서비스호출 파라미터

      /**
       * 사용자 정보 변수
       */
      userInfo: {},                                                 // 조직 및 컨설턴트 정보
      isMngr: false,                                                // 지점장 여부 
      pOrgDataMSPCM121M: {},                                        // 최근 조회조건 데이터 

       /**
       * 화면 변수
       */
      isNoData: false,            
      isLoading: false,

      /**
       * 더보기 key 변수 
       */ 
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}], 
      pageRowCnt: '20',        
      stndKeyVal: 'start',

      /**
       * 체크박스 변수
       */
      isCheckAll: false,                                            // 전체체크 여부 
      isDisabledCheck: false,                                       // 체크박스 비활성화 여부 (타 컨설턴트 고객 호출 하면 체크박스 비활성화) 
      totalCount: 0,                                                // 전체 고객수 
      selectedItems: [],                                            // 선택된 고객 리스트 

      /**
       * 조회 변수
       */ 
      cmCustCntcInfoListSVO: [],                                    // 고객알람(고객접촉정보) 조회 데이터  
      cmCustCntcGrpListSVO: [],                                     // 대분류 CODE-LIST 데이터 
      cmCustCntcIdListSVO: [],                                      // 중분류 CODE-LIST 데이터
      searchedCustNm: '',                                           // 고객명 
      custCntcGrpIdCd: '',                                          // 대분류 (=고객접촉그룹ID) 
      custCntcIdCd: '',                                             // 중분류 (=고객접촉ID)
      exceptedCount: '',                                            // 제외정보 
      openCd: cmConstants.CUST_CNTC_INFO_RLS_YN_CD[0].value,        // 개봉여부
      touchCd: cmConstants.CUST_CNTC_INFO_TUCH_YN_CD[0].value,      // 터치여부
      mktAgreeCd: cmConstants.CUST_CNTC_INFO_MKT_CNSNT_CD[0].value, // 마케팅동의 여부
      chnlCustScCd: cmConstants.CUST_CNTC_INFO_CUST_SC_CD[0].value, // 고객구분

      // 발생시작일/종료일 기본값이 [ 30일전부터 당일까지 ] 이다.
      today: moment(new Date()).format('YYYY-MM-DD'),
      startDate: this.$bizUtil.getFirstDayOfMonth(new Date(moment(this.today)), 'String'),   // 발생시작일
      endDate: this.$bizUtil.getDateToString(new Date(), '-'),                               // 발생종료일

      searchedItems: {},                                            // 검색결과 데이터 
      
      /**
       * 제어 변수 
       */  
      srchScCd: 'A',                                                // 서비스구분코드(최초조회시만 A 나머지는 B)
      isSearched: false,                                            // 조회이력 저장 변수 
      isCleared: true,                                              // 조회조건 초기화 여부 
      
      /** 
       * 팝업 연동
       */
      popup122: {},                                                 // MSPCM122P 고객알람(고객접촉정보) 목록 검색
      popup126: {},                                                 // MSPCM126P 고객알람(고객접촉정보) 상세조회 

    }
  },//data

  created () {

    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)     // backKey Event 등록
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')                       // post 호출 전 Progress 타입 설정

    this.userInfo = this.getStore('userInfo').getters.getUserInfo                   // 사용자 정보

    /**
     * 지점장 여부를 확인한다.
     * 해당 사용자는 본인이 아닌 FC번호로 조회가능하다.
     */
    if(this.$cmUtil.isSrchOrgCombo() === 'Y') this.isMngr = true
    
    this.fn_Submit()

  },//created
  
  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  beforeDestroy () {

    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickBackBtn)      //backKey Event 해제
    window.vue.getStore('progress').dispatch('FULL')                                  // post 호출 전 Progress 타입 설정

  },//beforeDestroy
  
  watch: {
    
    /**
     * 전체 체크여부를 확인한다.
     */
    checkedCount(){
      let cnt = this.cmCustCntcInfoListSVO.length 
      if(this.checkedCount == cnt && this.cmCustCntcInfoListSVO.length != 0){
        this.isCheckAll  = true
      }else{
        this.isCheckAll  = false
      }
    },

  },//watch

  computed: {

    /**
     * 선택된 데이터 개수를 구한다.
     */
    checkedCount(){
      return this.cmCustCntcInfoListSVO.filter(item => item.checked).length
    },
    
    /**
     * 선택된 데이터를 추출한다. 
     */ 
    checkedList(){
      return this.cmCustCntcInfoListSVO.filter(item => item.checked)
    },

  },//computed

  methods: {

    fn_OnClickListItem(index) {
      
      if(this.isDisabledCheck) return 
      
      this.$refs[`chk${index}`].onClick()
    },
    
    /**
     * @description 초기 조회조건으로 초기화 한다.
     */
    fn_ClearCond(){

      this.pOrgDataMSPCM121M = {}                                                
      this.searchedCustNm    = ''                                                
      this.custCntcGrpIdCd   = ''                                                
      this.custCntcIdCd      = ''                                                
      this.exceptedCount     = ''                                               
      this.openCd            = cmConstants.CUST_CNTC_INFO_RLS_YN_CD[0].value     
      this.touchCd           = cmConstants.CUST_CNTC_INFO_TUCH_YN_CD[0].value    
      this.mktAgreeCd        = cmConstants.CUST_CNTC_INFO_MKT_CNSNT_CD[0].value  
      this.chnlCustScCd      = cmConstants.CUST_CNTC_INFO_CUST_SC_CD[0].value    
      this.startDate         = this.$bizUtil.getFirstDayOfMonth(new Date(moment(this.today)), 'String'), 
      this.endDate           = this.$bizUtil.getDateToString(new Date(), '-')                              
      this.srchScCd          = 'A'      
      this.isSearched        = false   
      this.searchedItems     = {}      

    },
    
    /**
     * @description 체크된 값이 있는지 확인 후, 뒤로가기 실행 함수를 호출한다.
     */
    fn_ClickBackBtn(){
      
      if (this.checkedCount > 0) {
        this.fn_confirm()
        return 
      } 
      
      this.fn_BackToPrevious()

    },

    /**
     * @description - (현재조건값 === 초기조건값) 뒤로가기를 한다.
     *              - (현재조건값 !== 초기조건값) 조건 초기화 후 조회한다.
     */
    fn_BackToPrevious(){

      if(this.isSearched && !this.isCleared){  
        this.fn_ClearCond()
        this.fn_Submit()
      }else{
        // 메뉴로 진입했으면 홈화면으로 이동한다.
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        this.$router.go(-1)
      }

    },
    
    /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     */
    fn_Submit(){

      // 초기화  
      this.isNoData    = false
      this.totalCount  = 0  
      this.stndKeyVal  = 'start'               
      this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] // 초기로드 or 조회버튼시 : nextKey
      this.cmCustCntcInfoListSVO.splice(0)

      // 대분류 및 중분류 초기화 및 기본설정 
      if(this.srchScCd === 'A'){
        this.cmCustCntcGrpListSVO.splice(0)  // 대분류  
        this.cmCustCntcGrpListSVO.push({value: '', text: '전체'}) 
        this.cmCustCntcIdListSVO.splice(0)   // 중분류  
        this.cmCustCntcIdListSVO.push({value: '', text: '전체', grpId: ''}) 
      }

      // 체크된 고객 초기화
      let selectItems_init = []
      this.$cmUtil.setSelectedCustList(this, selectItems_init)

      // 조직 및 컨설턴트 데이터 세팅 
      this.lv_inputParam.cnsltNo      = this.userInfo.userId        // 컨설턴트번호
      this.lv_inputParam.dofOrgNo     = this.userInfo.onpstDofNo    // 소속지역단조직번호
      this.lv_inputParam.fofOrgNo     = this.userInfo.onpstFofOrgNo // 소속지점조직번호
      this.lv_inputParam.hofOrgNo     = this.userInfo.onpstHofOrgNo // 소속사업부조직번호

      if(this.pOrgDataMSPCM121M.cnslt !== undefined && this.isMngr === true) {
        // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
        if(this.userInfo.userId !== this.pOrgDataMSPCM121M.cnslt.key){
          this.isDisabledCheck = true
        } else {
          this.isDisabledCheck = false
        }
        // 선택된 조직 및 컨설턴트 데이터 세팅 
        this.lv_inputParam.cnsltNo  = this.pOrgDataMSPCM121M.cnslt.key  
        this.lv_inputParam.dofOrgNo = this.pOrgDataMSPCM121M.dofOrg.key 
        this.lv_inputParam.fofOrgNo = this.pOrgDataMSPCM121M.fofOrg.key
        this.lv_inputParam.hofOrgNo = this.pOrgDataMSPCM121M.hofOrg.key 

      }else{
        this.isDisabledCheck = false
      }

      // 조회조건 초기화 
      this.lv_inputParam.userScCd        = 'F'                                              
      this.lv_inputParam.userSnoId       = this.userInfo.userId                              
      this.lv_inputParam.custNm          = this.searchedCustNm                              
      this.lv_inputParam.srchScCd        = this.srchScCd                                     
      this.lv_inputParam.custCntcGrpId   = this.custCntcGrpIdCd                             
      this.lv_inputParam.custCntcId      = this.custCntcIdCd                                
      this.lv_inputParam.rlsYn           = this.openCd.trim()                                
      this.lv_inputParam.tuchYn          = this.touchCd.trim()                               
      this.lv_inputParam.mktCnsntYn      = this.mktAgreeCd.trim()                            
      this.lv_inputParam.chnlCustScCd    = this.chnlCustScCd.trim()                          
      this.lv_inputParam.strYmd          = this.startDate.replace(/-/gi, '').substring(0, 8) 
      this.lv_inputParam.endYmd          = this.endDate.replace(/-/gi, '').substring(0, 8)   

      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK') // post 호출 전 Progress 타입 설정.

      // 서비스 호출 
      this.fn_GetService()

    },

    /**
     * @description 고객알람(고객접촉정보) 목록 조회 서비스 호출 후, 콜백 데이터를 처리한다.
     * @callback    function(response) 
     */
    fn_GetService(){
      if (this.stndKeyVal === 'end') return

      // const trnstId = 'txTSSCM52S1'
      // const trnstId = 'txMSPCM52S1'
      const trnstId = 'txTSSCM48S9'
      const auth    = 'S' 

      let lv_Vm   = this
      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response){

          // 서버 데이터 가져오기
          if(response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003'){

            let responseList    = response.body.cMCustCntcInfoListSVO  // 고객알람(고객접촉정보) 리스트 
            lv_Vm.exceptedCount = response.body.respMsgCntnt           // 제외정보

            // MSPCM122P(고객알람(고객접촉정보) 수신설정)에서 제외된 값은 MSPCM121M(고객알람(고객접촉정보))에서 UPDATE 된다.
            // 따라서 조회조건 결과 중 제외정보는 여기서 가공(UPDATE)해줘야 한다.
            // if(JSON.stringify(lv_Vm.searchedItems) !== '{}'){
            //   let prcExceptedCount  = new String(lv_Vm.exceptedCount.trim()).split('개')[0] + '개'
            //   lv_Vm.searchedItems.exceptedCount.text = prcExceptedCount;
            // }

            // 최초 호출 때만 대분류, 중분류 데이터를 저장해준다.
            if(lv_Vm.srchScCd === 'A'){   

              let responseGrpList = response.body.cMCustCntcGrpListSVO,
                  responseIdList  = response.body.cMCustCntcIdListSVO
              // 대분류 데이터 저장 
              responseGrpList.forEach((item, index) => {
                let grpObj   = {}
                grpObj.value = responseGrpList[index].custCntcGrpId
                grpObj.text  = responseGrpList[index].custCntcGrpNm
                lv_Vm.cmCustCntcGrpListSVO.push(grpObj)
              })
              // 중분류 데이터 저장 
              responseIdList.forEach((item, index) => {
                let idObj   = {}
                idObj.value = responseIdList[index].custCntcId
                idObj.text  = responseIdList[index].custCntcNm
                idObj.grpId = responseIdList[index].custCntcGrpId
                lv_Vm.cmCustCntcIdListSVO.push(idObj)
              })

            }

            if(responseList !== null){

              // 고객알람(고객접촉정보) 조회 데이터 가공 
              responseList.forEach((item, index) => {

                item.ralDob     = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.$bizUtil.getDotDateToFullDate(item.ralDob)) // 생년월일 
                item.occuYmd    = lv_Vm.$commonUtil.dateDashFormat(item.occuYmd)                                     // 발생일
                item.rlsYmd     = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.$bizUtil.getDotDateToFullDate(item.rlsYmd)) // 개봉일
                item.rntTuchYmd = lv_Vm.$commonUtil.dateDashFormat(item.rntTuchYmd)                                  // 터치일 
                lv_Vm.$set(responseList[index], 'checked', false)                                                    // 선택(체크박스) 여부 
              
              })

              // 더보기 데이터 저장 
              if(lv_Vm.stndKeyVal !== 'start'){
                responseList.forEach((item) => { lv_Vm.cmCustCntcInfoListSVO.push(item) })
              }else{
                lv_Vm.cmCustCntcInfoListSVO = responseList  // 1 page
              }

              // 더보기 키값
              lv_Vm.stndKeyVal = response.trnstComm.stndKeyList    
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
                lv_Vm.stndKeyVal = 'end'    // 다음페이지 데이터 존재안함(마지막 페이지)
              }else{
                lv_Vm.stndKeyVal = 'more'   // 다음페이지 데이터 존재함
              }
              
              // 조회데이터 총 개수
              lv_Vm.totalCount = lv_Vm.cmCustCntcInfoListSVO.length
              
            }

            lv_Vm.srchScCd = 'B' // 최초조회후 B로 세팅 
            
          }else{
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false

          // 데이터 유무 
          if (lv_Vm.cmCustCntcInfoListSVO.length === 0) {
            lv_Vm.isNoData = true    
          } else {
            lv_Vm.isNoData = false  
          }
          
          // 알림에서 선택한 경우 호출
          if (lv_Vm.$route.params.detailOpen !== undefined && lv_Vm.$route.params.detailOpen === true) {
            lv_Vm.fn_OpenWithAlaram() 
          }
                
        })
        .catch(function (error) {
          window.vue.error(error)
        })

    },

   /**
     * @description 조회목록을 전체선택 한다. 
     */
    fn_ClickCheckAll(){

      this.$nextTick(() =>{
        
        if(this.cmCustCntcInfoListSVO.length > 0){
          this.cmCustCntcInfoListSVO.forEach((item, index) => {
            if(this.isCheckAll){
              this.cmCustCntcInfoListSVO[index].checked = true
            }else{
              this.cmCustCntcInfoListSVO[index].checked = false
            }
          })
          this.selectedItems = [] // 초기화
          this.selectedItems = this.checkedList

          // BottomSheet 제어 
          this.fn_ControlBottomSheet()
        }
      })

    },

    /**
     * @description 조회목록을 부분선택 한다.
     */
    fn_ClickCheckPartial(){

      this.selectedItems = [] // 초기화
      this.selectedItems = this.checkedList

      // BottomSheet 제어 
      this.fn_ControlBottomSheet()

    },
    
    /**
     * @description BottomSheet를 제어한다.
     *              조회목록을 부분/전체선택하면 노출된다.
     */
    fn_ControlBottomSheet() {
      this.$cmUtil.setSelectedCustList(this, this.checkedList)

    },

    /**
     * @description 고객알람(고객접촉정보) 고객검색 팝업을 제어한다.
     */
    fn_OpenMSPCM122P(){

      this.popup122 = this.$bottomModal.open(MSPCM122P,{
        properties: {
          pPage: this.$options.screenId,
          pIsMngr: this.isMngr,                         // 지점장 여부 
          pUserInfo: this.userInfo,                     // 사용자 정보 
          pOrgData: this.pOrgDataMSPCM121M,             // 최근 조직 및 컨설턴트
          pSelectedItems: this.searchedItems,           // 조회조건 결과 
          pSearchedCustNm: this.searchedCustNm,         // 고객명
          pCustCntcGrpIdCds: this.cmCustCntcGrpListSVO, // 대분류 CODE-LIST
          pCustCntcGrpIdCd: this.custCntcGrpIdCd,       // 대분류
          pCustCntcIdCds: this.cmCustCntcIdListSVO,     // 중분류 CODE-LIST
          pCustCntcIdCd: this.custCntcIdCd,             // 중분류
          pExceptedCount: this.exceptedCount,           // 제외정보  
          pOpenCd: this.openCd,                         // 개봉여부
          pTouchCd: this.touchCd,                       // 터치여부
          pMktAgreeCd: this.mktAgreeCd,                 // 마케팅동의 여부
          pChnlCustScCd: this.chnlCustScCd,             // 고객구분
          pStartDate: this.startDate,                   // 발생시작일 
          pEndDate: this.endDate,                       // 발생종료일 
        },

        listeners: {
          searchPopup: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup122)

            this.searchedCustNm    = pData.rtnData.searchedCustNm         
            this.custCntcGrpIdCd   = pData.rtnData.custCntcGrpIdCd        
            this.custCntcIdCd      = pData.rtnData.custCntcIdCd           
            this.openCd            = pData.rtnData.openCd                 
            this.touchCd           = pData.rtnData.touchCd                
            this.mktAgreeCd        = pData.rtnData.mktAgreeCd            
            this.chnlCustScCd      = pData.rtnData.chnlCustScCd        
            this.startDate         = pData.rtnData.startDate          
            this.endDate           = pData.rtnData.endDate          
            this.pOrgDataMSPCM121M = pData.rtnData.pOrgData             
            this.searchedItems     = pData.rtnData.selectedItems         
            this.isCleared         = pData.rtnData.isCleared             
            this.isSearched        = true                               

            // 고객알람(고객접촉정보) 조회 
            this.fn_Submit(true)


          },
        }
      })
    },

    /**
     * @description 고객알람(고객접촉정보) 상세조회 팝업을 제어한다.
     * @param       {Object}  item 선택된 고객알람(고객접촉정보) 데이터 
     */
    fn_OpenMSPCM126P(item){
      
      window.vue.getStore('progress').dispatch('FULL') // post 호출 전 Progress 타입 설정.
      
      this.popup126 = this.$bottomModal.open(MSPCM126P,{
        properties: {
          pPage: this.$options.screenId,
          pUserInfo: this.userInfo,                                  // 사용자정보
          pOrgData: this.pOrgDataMSPCM121M,                          // 최근 조직 및 컨설턴트 정보
          pChnlCustId: item.chnlCustId,                              // 채널아이디 
          pOccuYmd: item.occuYmd.replace(/-/gi, '').substring(0, 8), // 발생일
          pcnsltNo: this.lv_inputParam.cnsltNo,                      // 컨설턴트 번호
          pCustCntcId: item.custCntcId,                              // 중분류 
          pCntcSno: item.cntcSno,                                    // 접촉일련번호
        },

        listeners: {
          closePopup: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup126)
          },
        }
      })

    },

    /**
     * @description 고객명을 클릭할 때 nameUI를 호출한다.
     * @param       {Object}  item 선택된 1건고객 데이터 
     */
    fn_OpenCustInfoBs (item) {

      let lv_Vm = this
      let cnsltNo = ''
      let nmUiObject = {}
      
      if(this.pOrgDataMSPCM121M.cnslt === undefined || this.pOrgDataMSPCM121M.cnslt === null || this.pOrgDataMSPCM121M.cnslt === ''){
        cnsltNo = this.userInfo.userId
      }else{
        cnsltNo = this.pOrgDataMSPCM121M.cnslt.key
      }

      nmUiObject = {
        chnlCustId : item.chnlCustId,         //고객채널 ID
        parentId   : this.$options.screenId,  //화면ID+
        cnsltNo : cnsltNo,
        contNo : item.contNo,
        custNm : item.custNm,
      }

      lv_Vm.$cmUtil.fn_OpenCustNmUiCard(this, nmUiObject,(modalId)=>{
        return
      })
      
    },

    /**
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     */
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              this.fn_BackToPrevious()
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },

    /**
     * @description 알림으로 진입했을 경우, 해당되는 고객알람(고객접촉정보) 상세조회 팝업을 열어준다.
     */
    fn_OpenWithAlaram() {
      
      if(this.$route.params.occuYmd !== undefined && this.$route.params.cntcSno !== undefined && this.$route.params.custCntcId !== undefined){
        if (this.$route.params.occuYmd.trim() !== '' && this.$route.params.cntcSno.trim() !== '' && this.$route.params.custCntcId.trim() !== '') {
          let item = {
            chnlCustId: null,
            occuYmd: this.$route.params.occuYmd.replace(/-/gi, '').substring(0, 8),
            custCntcId: this.$route.params.custCntcId,
            cntcSno: this.$route.params.cntcSno
          }
          this.fn_OpenMSPCM126P(item)
        }else{
          console.log('Invalid Data.') 
        }
      }else{
        console.log('Invalid Data');
      }

    }
   
 },// methods

}//export default


</script>

<style lang="scss" scoped>
.need-cs-card.guide:after {
  top: 1px;
}
</style>