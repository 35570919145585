/*
 * 업무구분 : 고객관리
 * 화면 명 : MSPCM126P
 * 화면 설명: 고객알람 상세조회
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.01.30
 * 작 성 자: 서영화 
 */

<template>
  <ur-page-container class="msp" title="상세조회" :show-title="true" type="subpage" @on-header-left-click="fn_ClosePopup">
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap">
      <ur-box-container alignV="start" componentid="ur-box-container_001" direction="column" class="ns-info-area">
        <ur-box-container alignV="start" componentid="ur-box-container_002" direction="column" class="info-box pt10">
          <!-- 고객명 -->
          <ur-box-container alignV="start" componentid="ur-box-container_003" direction="column" class="row-box bd-b-Ty2 pt16 pb11">
            <span class="w100px fs16rem fex-clumn fexJsCtTy4 h100"><span>고객명</span></span>
            <span class="flex-1 fs17rem fwb">{{ dtlInqrObj.custNm }}</span>  
          </ur-box-container>
          <!-- 이메일 -->
          <ur-box-container alignV="start" componentid="ur-box-container_004" direction="column" class="row-box bd-b-Ty2 pt16 pb11">
            <span class="w100px fs16rem fex-clumn fexJsCtTy4 h100"><span>이메일</span></span>
            <span class="flex-1 fs17rem fwb">{{ dtlInqrObj.emailAddr }}</span>
          </ur-box-container>
          <!-- 휴대폰 -->
          <ur-box-container alignV="start" componentid="ur-box-container_005" direction="column" class="row-box bd-b-Ty2 pt16 pb11">
            <span class="w100px fs16rem fex-clumn fexJsCtTy4 h100"><span>휴대폰</span></span>
            <span class="flex-1 fs17rem fwb">{{ dtlInqrObj.celno }}</span>
          </ur-box-container>
          <!-- 주소 -->
          <ur-box-container alignV="start" componentid="ur-box-container_006" direction="column" class="row-box bd-b-Ty2 pt16 pb11">
            <span class="w100px fs16rem fex-clumn fexJsCtTy4 h100"><span>주소</span></span>
            <span class="flex-1 fs17rem fwb">{{ dtlInqrObj.homBasAddr }}</span>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container alignV="start" componentid="ur-box-container_007" direction="column" class="info-box pb20">
          <!-- 알림정보 -->
          <ur-box-container alignH="start" direction="row" class="fexJsCtTy1 pb10 pt20">
            <span class="con-title3">알림정보</span>
            <mo-button v-if="isNcontPrg" :disabled="isDisabledBtn" @click="fn_MoveMSPPS250M" color="normal" shape="border" size="small" class="blue">신계약 진행</mo-button>
          </ur-box-container> 
          <div class="row-box pb80 w100">
            <div class="bgcolor-1 pt16 pb16 pl20 pr20 bd-radius-10 h200px overflow-scroll-y">
              <ul class="hauto fs17rem crTy-bk1 bullet-type-4">
                <template v-for="(item, idx) in dtlInqrObj.rsnDtlCntnt">
                  <li v-if="!$bizUtil.isEmpty(item)" :key="idx">{{ item }}</li>
                </template>
              </ul>
            </div>
          </div>
        </ur-box-container>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="ur-box-container_009" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="ur-box-container_010" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_01" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_MoveMSPCM270M">고객상세</mo-button>
            <mo-button componentid="mo_button_02" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ClosePopup">확인</mo-button>
          </div>
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>

import psCommonUtil from '@/ui/ps/comm/PSCommUtil'

export default {
  name: 'MSPCM126P', 
  screenId: 'MSPCM126P', 
  components: {
    psCommonUtil
  },//componets,

  props:{
    pPage: String,            // 부모 페이지명 
    pUserInfo: {              // 사용자정보
      type: Object,
      default: {}
    },
    pOrgData: {               // 컨설턴트 및 조직정보 
      type: Object,
      default: {}
    },
    pChnlCustId: String,      // 채널아이디 
    pOccuYmd: String,         // 발생일
    pcnsltNo: String,         // 컨설턴트 번호
    pCustCntcId: String,      // 중분류
    pCntcSno: String,         // 접촉일련번호
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  data () {
    return {
      lv_inputParam: {},      // 서비스호출 파라미터

      /**
       * 화면 변수
       */
      isLoading: false,
      isNcontPrg: false,      // 신계약 진행 버튼 노출여부 
      isDisabledBtn: false,   // 신계약 진행 버튼 접근여부 

      dtlInqrObj: {},         // 상세조회 데이터

    }
  },//data

  created () {

    this.fn_Submit()

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  watch: {},//watch

  computed: {},//computed

  methods: {
    
    /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     */
    fn_Submit () {

      // 본인 계정으로만 신계약 진행 버튼 클릭 가능.
      if(this.pOrgData.cnslt !== undefined){
        if(this.pUserInfo.userId !== this.pOrgData.cnslt.key){
          this.isDisabledBtn = true
        }else{
          this.isDisabledBtn = false
        }
      }else{
        this.isDisabledBtn = false
      }

      this.lv_inputParam.cnsltNo    = this.pcnsltNo       
      this.lv_inputParam.custCntcId = this.pCustCntcId    
      this.lv_inputParam.occuYmd    = this.pOccuYmd       
      this.lv_inputParam.cntcSno    = this.pCntcSno       

      // 초기화 
      this.dtlInqrObj = {}

      // 서비스 호출
      this.fn_GetService()

    },

    /**
     * @description 상세조회 서비스 호출 후, 콜백 데이터를 처리한다.
     * @callback    function(response) 
     */
    fn_GetService () {

      // const trnstId = 'txTSSCM52S2'
      // const trnstId = 'txMSPCM52S2'
      const trnstId = 'txTSSCM49S1'
      const auth    = 'S'

      let lv_Vm   = this
      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {

          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {

            let responseList = response.body

            if (responseList !== null) {

              let compCode = responseList.custCntcId  
              compCode = lv_Vm.pCustCntcId  

              // 상세조회 데이터 저장 
              lv_Vm.dtlInqrObj             = responseList 
              lv_Vm.dtlInqrObj.custCntcId  = compCode                                 // 고객접촉 ID
              lv_Vm.dtlInqrObj.rsnDtlCntnt = lv_Vm.dtlInqrObj.rsnDtlCntnt.split('\n') // 알림정보
              
              // BH01:진단신청 BH02:신계약심사완료 BH03:계약확인서비스 BH04:신계약증권발행 BH05:신계약서류검증완료 BH06:신계약심사완료(취소) BH07:신계약서류검증완료(최소) BH08:진단싱청취소
              // 위의 경우 신계약진행 버튼 생성
              if (compCode === 'BH01' || compCode === 'BH02' || compCode === 'BH03' || compCode === 'BH04' || compCode === 'BH05' || compCode === 'BH06' || compCode === 'BH07' || compCode === 'BH08') {
                lv_Vm.isNcontPrg = true
              }

            }
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false

        })
        .catch(function (error) {
          window.vue.error(error)
        })

    },

    /**
     * @description 가입설계관리 > 신계약진행현황(MSPPS250M) 화면으로 이동한다.
     */
    fn_MoveMSPPS250M(){

      psCommonUtil.fn_RoutepushSetKeepAlive({name: 'MSPPS250M'}, false)
      this.$emit('closePopup', { isMoved: true })

    },

    /**
     * @description 고객카드 홈(MSPCM270M) 화면으로 이동한다.
     */
    fn_MoveMSPCM270M(){

      let routerParam = {}
      let cnstlNo     = ''
          
      if(this.pOrgData.cnslt === undefined || this.pOrgData.cnslt === null || this.pOrgData.cnslt === ''){
        cnstlNo = this.pUserInfo.userId
      }else{
        cnstlNo = this.pOrgData.cnslt.key
      }

      routerParam.callerId   = 'MSPPM121M'                  // 송신화면
      routerParam.viewId     = 'MSPCM271D'                  // 수신화면
      routerParam.chnlCustId = this.dtlInqrObj.chnlCustId   // 채널아이디
      routerParam.cnsltNo    = cnstlNo                      // 컨설턴트 번호 
    
      this.$router.push({ name: 'MSPCM270M', params: routerParam })
      this.$emit('closePopup', { isMoved: true })
      
    },

   /**
    * @description 상세조회 팝업을 닫는다.
    */
    fn_ClosePopup(){

      this.$emit('closePopup', { isMoved: false })

    }

 },// methods

}//export default


</script>