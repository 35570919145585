/*
 * 업무구분 : 고객관리
 * 화면 명 : MSPCM122P
 * 화면 설명: 고객알람 > 고객검색   
 * 접근권한: FC, 지점장
 * 작 성 일: 2023.01.30
 * 작 성 자: 서영화 
 */

<template>

  <ur-page-container title="고객검색" :show-title="true" type="subpage" class="msp" >
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap pb120">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-area pt30 pb0">
        <!-- 조직관련 COMPONENT : 관리자 접근시 버튼 노출 -->
        <SearchCondOrgCM ref="refSearchOrg" v-show="pIsMngr" v-model="lv_SelectOrg" :isFofType="1" :pOrgData="pOrgData122P" @selectResult="fn_SelectCnsltResult"/>
        <!-- 고객명 -->
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="info-box pb20">
          <div class="info-title-wrap">
            <span class="info-title fs14rem fwn crTy-bk7">고객명</span>
          </div>
          <ur-box-container alignV="start" componentid="" direction="column">
            <mo-text-field v-model="searchedCustNm" type="none" class="mb0" clearable underline placeholder="고객명을 입력해주세요." />  
          </ur-box-container>
        </ur-box-container>
        <!-- 대분류 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="info-box pb20">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">대분류</span>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" >
            <msp-bottom-select :items="custCntcGrpIdCds" v-model="custCntcGrpIdCd.value" underline class="ns-dropdown-sheet mb0 mt0" placeholder="전체" bottom-title="대분류" closeBtn/>
          </ur-box-container>
        </ur-box-container>
        <!-- 중분류 -->
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="info-box pb30">
          <div class="info-title-wrap mb0">
            <span class="info-title fs14rem fwn crTy-bk7">중분류</span>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column">
            <msp-bottom-select :items="custCntcIdCds" v-model="custCntcIdCd.value"  underline class="ns-dropdown-sheet mb0 mt0" placeholder="전체" bottom-title="중분류" closeBtn/>
          </ur-box-container>
        </ur-box-container>
        <!-- 제외정보 -->
        <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="info-box pb20">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title fs14rem fwn crTy-bk7">제외정보</span>
            <mo-button :disabled="isDisabledButton" @click="fn_OpenMSPCM123P()" 
                        color="normal" shape="border" size="medium" class="blue crTy-bk4 bdTy4" >고객알람 수신 설정</mo-button>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="ns-contract-list">
            <div class="bdTy10 w100 txt-left">
              <p class="fs16rem pl16 pr16"><strong>{{ exceptedCount }}</strong>개 제외 설정</p>
            </div>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
        <!-- 발생일자 -->
        <ur-box-container alignV="start" componentid="ur_box_container_017" direction="column" class="ns-add-area pt0 pb20">
          <span class="ns-title mb0 fs14rem fwn crTy-bk7">발생일자</span>
          <div class="add-date">
            <mo-date-picker v-model="startDate" bottom placeholder="선택일" class="ns-date-picker" />
            <span class="tilde"></span>
            <mo-date-picker v-model="endDate" bottom placeholder="선택일" class="ns-date-picker" />
          </div>
        </ur-box-container>
        <!-- 개봉여부 -->
        <ur-box-container alignV="start" componentid="ur_box_container_009" direction="column" class="ns-input-box pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">개봉여부</span>
          <ur-box-container alignV="start" componentid="ur_box_container_010" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="openCd.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in openCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 터치여부(작년 1년 내) -->
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-input-box pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">터치여부(작년 1년 내)</span>
          <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="touchCd.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in touchCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 마케팅동의 -->
        <ur-box-container alignV="start" componentid="ur_box_container_013" direction="column" class="ns-input-box pb20">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">마케팅동의</span>
          <ur-box-container alignV="start" componentid="ur_box_container_014" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="mktAgreeCd.value" class="chip-type-wrap" solid primary>
              <mo-segment-button v-for="(item, idx) in mktAgreeCds" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
        <!-- 고객구분 -->
        <ur-box-container alignV="start" componentid="ur_box_container_015" direction="column" class="ns-input-box mb80 pb60">
          <span class="ns-title mb10 fs14rem fwn crTy-bk7">고객구분</span>
          <ur-box-container alignV="start" componentid="ur_box_container_016" direction="column" class="ns-segment">
            <mo-segment-wrapper v-model="chnlCustScCd.value" class="chip-type-wrap" solid primary>
              <template v-for="(item, idx) in chnlCustScCds">
                <mo-segment-button v-if="idx < 3" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
              </template>
            </mo-segment-wrapper>
            <mo-segment-wrapper v-model="chnlCustScCd.value" class="chip-type-wrap mt10" solid primary>
              <template v-for="(item, idx) in chnlCustScCds">
                <mo-segment-button v-if="idx >= 3" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
              </template>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>
      
      <!-- 초기화/조회 버튼 -->
      <ur-box-container alignV="start" componentid="ur_box_container_009" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ClickClear">초기화</mo-button>
          <mo-button componentid="mo_button_003" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_ClickSearch">조회</mo-button>
        </div>
      </ur-box-container>

    </ur-box-container>
  </ur-page-container>
</template>

<script>

import moment          from 'moment'
import cmConstants     from '~/src/config/constants/cmConstants'
import SearchCondOrgCM from '@/ui/cm/SearchCondOrgCM.vue'
import MSPCM123P       from '@/ui/cm/MSPCM123P'                  // 고객알람 수신 설정 

export default {

  name: 'MSPCM122P', 
  screenId: 'MSPCM122P', 
  components: {    
    SearchCondOrgCM,
    MSPCM123P,
  },//componets,

  props:{
    pPage: String,                // 부모 페이지명 
    pIsMngr: Boolean,             // 지점장여부 
    pOrgData: {                   // 조직 및 컨설턴트 정보 
      type: Object,
      default: {}
    },
    pUserInfo: {                  // 현재 사용자 조직 및 컨설턴트 정보 
      type: Object,
      default: {}
    },
    pSearchedCustNm: String,      // 고객명 
    pCustCntcGrpIdCds: Array,     // 대분류
    pCustCntcGrpIdCd: String,    
    pCustCntcIdCds: Array,        // 중분류  
    pCustCntcIdCd: String,      
    pExceptedCount: String,       // 제외된 고객 수 
    pOpenCd: String,              // 개봉여부
    pTouchCd: String,             // 터치여부 
    pMktAgreeCd: String,          // 마케팅동의 
    pChnlCustScCd: String,        // 고객구분 
    pStartDate: String,           // 발생시작일 
    pEndDate: String,             // 발생종료일 

    pSelectedItems: {             // 조회조건 결과 
      type: Object,
      default: {}
    }
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  data () {
    return {
      lv_ReturnVal: {},             // 조건 리턴값 
      isReturn: false,              // 리턴값 세팅여부 

      /**
       * 화면변수
       */
      isOpenPopup: true,            // 팝업이 열린시점 
      isDisabledButton: false,      // 고객알람 수신 설정 활성화 여부 

      /** 
       * 사용자 정보 변수
       */ 
      lv_SelectOrg: {},             // 조직 및 담당 컨설턴트 컴포넌트 데이터 
      pOrgData122P: {},             // 조직정보 
      userInfo: {},                 // 사용자정보 

      /** 
       * 조회 변수 
       */
      searchedCustNm: '',           // 고객명 
      custCntcGrpIdCds: [],         // 대분류 CODE-LIST
      custCntcGrpIdCd: {},          // 선택된 대분류 데이터 
      custCntcIdCdTotal: [],        // 전체 중분류 CODE-LIST
      custCntcIdCds: [],            // 특정 대분류에 소속된 중분류 CODE-LIST 
      custCntcIdCd: {},             // 선택된 중분류 데이터
      openCds: [],                  // 개봉여부 CODE-LIST
      openCd: {},                   // 선택된 개봉여부 데이터
      touchCds: [],                 // 터치여부 CODE-LIST
      touchCd: {},                  // 선택된 터치여부 데이터
      mktAgreeCds: [],              // 마케팅동의 여부 CODE-LIST
      mktAgreeCd: {},               // 선택된 마케팅동의 데이터
      chnlCustScCds: [],            // 고객구분 CODE-LIST
      chnlCustScCd: {},             // 선택된 고객구분 데이터
      today: moment(new Date()).format('YYYY-MM-DD'),
      startDate: '',                // 발생시작일자
      endDate: '',                  // 발생종료일자

      selectedItems: {},            // 조회조건 결과

      /**
       * 팝업 연동 
       */
      popup123: {},                 // MSPCM123P 고객알람 수신 설정 

      /**
       * 제어변수
       */
      isCleared: false,             // 조회조건 초기화 여부 

    }
  },//data

  created () {
    
    window.vue.getStore('progress').dispatch('FULL')              // post 호출 전 Progress 타입 설정.
    
    this.userInfo = this.getStore('userInfo').getters.getUserInfo // 사용자 데이터 설정 

    /**
     * props 데이터 및 공통코드 설정
     */
    this.pOrgData122P      = this.pOrgData           
    this.searchedCustNm    = this.pSearchedCustNm   
    this.custCntcGrpIdCds  = this.pCustCntcGrpIdCds  
    this.custCntcIdCdTotal = this.pCustCntcIdCds    
    this.custCntcIdCds     = JSON.parse(JSON.stringify(this.pCustCntcIdCds)) 
    this.startDate         = this.pStartDate         
    this.endDate           = this.pEndDate          
    this.openCds           = cmConstants.CUST_CNTC_INFO_RLS_YN_CD   
    this.touchCds          = cmConstants.CUST_CNTC_INFO_TUCH_YN_CD    
    this.mktAgreeCds       = cmConstants.CUST_CNTC_INFO_MKT_CNSNT_CD  
    this.chnlCustScCds     = cmConstants.CUST_CNTC_INFO_CUST_SC_CD   

    /**
     * 조회조건 결과 설정 
     */
    if(JSON.stringify(this.pSelectedItems) === '{}'){
      // 초기화 
      // 선택지점, 선택사업부, 선택지역단, 컨설턴트, 고객명, 대분류, 중분류, 제외정보, 발송일자, 개봉여부, 터치여부, 마케팅동의 여부, 고객구분
      this.selectedItems = {
        hofOrgCd       : {title: '', text: '', idx: 1},       
        dofOrgCd       : {title: '', text: '', idx: 2},       
        fofOrgCd       : {title: '', text: '', idx: 3},      
        cnsltEno       : {title: '컨설턴트', text: '', idx: 4},                                                    
        searchedCustNm : {title: '고객', text: '', idx: 5},                                   
        custCntcGrpIdCd: {title: '대분류', text: '', idx: 6},    
        custCntcIdCd   : {title: '중분류', text: '', idx: 7},       
        // exceptedCount  : {title: '제외정보', text: '', idx: 8},                               
        occuYmd        : {title: '발송일자', text: '', idx:8},                  
        openCd         : {title: '개봉여부', text: '', idx:9},            
        touchCd        : {title: '터치여부', text: '', idx:10},            
        mktAgreeCd     : {title: '마케팅동의 여부', text: '', idx:11}, 
        chnlCustScCd   : {title: '고객구분', text: '', idx:12},      
      }
      
    }else{
      this.selectedItems     = this.pSelectedItems 
    }

    // 수신설정 버튼 활성화여부 확인 
    this.fn_CheckUserInfo()
    
  },//created

  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    // 조회조건 선택값 설정 
    this.custCntcGrpIdCd   = Object.assign({}, this.pCustCntcGrpIdCds.filter((item) => item.value === this.pCustCntcGrpIdCd)[0]) 
    this.custCntcIdCd      = Object.assign({}, this.pCustCntcIdCds.filter((item) => item.value === this.pCustCntcIdCd)[0])      
    this.openCd            = Object.assign({}, this.openCds.filter((item) => item.value === this.pOpenCd)[0])                   
    this.touchCd           = Object.assign({}, this.touchCds.filter((item) => item.value === this.pTouchCd)[0])                  
    this.mktAgreeCd        = Object.assign({}, this.mktAgreeCds.filter((item) => item.value === this.pMktAgreeCd)[0])            
    this.chnlCustScCd      = Object.assign({}, this.chnlCustScCds.filter((item) => item.value === this.pChnlCustScCd)[0])      

  },//mounted

  watch: {
    
    /**
     * 선택된 대분류 데이터를 저장한다.
     */
    'custCntcGrpIdCd.value': function(val){ 
      
      // 대분류 선택 시, 대분류에 소속되는 중분류만 노출 
      this.custCntcIdCds.splice(0)  
      this.custCntcGrpIdCd = Object.assign({},  this.custCntcGrpIdCds.filter((item) => item.value === val)[0])

      if(val === ''){
        this.custCntcIdCdTotal.forEach(item => this.custCntcIdCds.push(item))
      }else{
        let lv_Filter = this.custCntcIdCdTotal.filter(item => item.grpId === val)
        this.custCntcIdCds.push({value: '', text: '전체', grpId: ''})
        lv_Filter.forEach(val => this.custCntcIdCds.push(val))
      }

      // 팝업띄우자마자 세팅할경우, custCntcIdCd은 pCustCntcIdCd로 세팅해야함.
      if(!this.isOpenPopup){
        this.custCntcIdCd = Object.assign({}, this.custCntcIdCdTotal[0])
      }else{
        this.isOpenPopup = false
      }

    },

    /**
     * 선택된 중분류 데이터를 저장한다.
     */
    'custCntcIdCd.value': function(val){ 
      this.custCntcIdCd = Object.assign({}, this.custCntcIdCds.filter((item) => item.value === val)[0])
    },
    
    /**
     * 선택된 개봉여부 데이터를 저장한다.
     */
    'openCd.value': function(val){ 
      this.openCd = Object.assign({}, this.openCds.filter((item) => item.value === val)[0])
    },
    
    /**
     * 선택된 터치여부 데이터를 저장한다.
     */
    'touchCd.value': function(val){ 
      this.touchCd = Object.assign({}, this.touchCds.filter((item) => item.value === val)[0])
    },
    
    /**
     * 선택된 마케팅동의 여부 데이터를 저장한다.
     */
    'mktAgreeCd.value': function(val){ 
      this.mktAgreeCd = Object.assign({}, this.mktAgreeCds.filter((item) => item.value === val)[0])
    },
    
    /**
     * 선택된 고객구분 데이터를 저장한다.
     */
    'chnlCustScCd.value': function(val){ 
      this.chnlCustScCd = Object.assign({}, this.chnlCustScCds.filter((item) => item.value === val)[0])
    },

    /**
     * 영문 고객명을 입력 시 대문자로 치환한다.
     */
    searchedCustNm () {
      let englishOrKorean = /[A-Za-z]/
      if (!this.$bizUtil.isEmpty(this.searchedCustNm)) {
        if (englishOrKorean.test(this.searchedCustNm)) {
          this.searchedCustNm = this.searchedCustNm.toUpperCase()
        }
      }
    }

  },//watch

  computed: {
    /**
     * 발생일자를 시작일 ~ 종료일 로 형식을 지정해준다
     */
    prcOccuYmd(){
      let val = this.startDate + ' ~ ' + this.endDate  
      return val
    },
    /**
     * 제외정보의 숫자만 추출한다.
     */
    exceptedCount(){ 
      return new String(this.pExceptedCount.trim()).split('개')[0] 
    }
  },//computed

  methods: {

    /**
     * @description 사용자의 정보와 선택한 컨설턴트의 정보가 다를 경우 수신설정 버튼을 비활성화 해준다.
     */
    fn_CheckUserInfo(){

      if(this.pOrgData122P.cnslt !== undefined && this.pIsMngr === true){
        if(this.pUserInfo.userId !== this.pOrgData122P.cnslt.key){
          this.isDisabledButton = true
        }else{
          this.isDisabledButton = false
        }
      }else{
        this.isDisabledButton = false
      }

    },

    /**
     * @description 조직 및 담당컨설턴트 변경 시, 전역변수에 세팅해준다.
     * @param       {Object} pData 조직 및 담당컨설턴트 정보 
     */
    fn_SelectCnsltResult(pData){
        this.lv_SelectOrg = pData
    },

    /**
     * @description 발생일자 유효성 검사를 한다.
     */
    fn_CheckDateVal(){

      let isValid  = true,
          confirm  = this.getStore('confirm')

      if (new Date(this.startDate) > new Date(this.endDate)) {
        //toast = '조회 시작일은 종료일 보다 클 수 없습니다.'
        confirm.dispatch('ADD', this.$t('cm').ECMC087)
        isValid = false
      }

      if (new Date(this.startDate) < new Date(moment(this.nowDate).add(-12, 'months'))) {
        //toast = '발생일은 현재일 기준 1년전까지만 선택 가능합니다.'
        confirm.dispatch('ADD', this.$t('cm').ECMC088)
        isValid = false
      }

      return isValid

    },

    /**
     * @description 선택된 조건값을 초기 조건값으로 초기화 한다.
     */
    fn_ClickClear(){
      
      // 관리자일 경우, 조직정보 초기화 
      if(this.pIsMngr) this.$refs.refSearchOrg.fn_GetFofRol()

      this.pOrgData122P      = {}
      this.searchedCustNm    = ''
      this.custCntcGrpIdCd   = Object.assign({}, this.custCntcGrpIdCds[0])
      this.custCntcIdCdTotal = JSON.parse(JSON.stringify(this.pCustCntcIdCds))
      this.custCntcIdCd      = Object.assign({}, this.custCntcIdCds[0])
      this.openCd            = Object.assign({}, this.openCds[0])
      this.touchCd           = Object.assign({}, this.touchCds[0])
      this.mktAgreeCd        = Object.assign({}, this.mktAgreeCds[0])
      this.chnlCustScCd      = Object.assign({}, this.chnlCustScCds[0])
      this.startDate         = this.$bizUtil.getFirstDayOfMonth(new Date(moment(this.today)), 'String')
      this.endDate           = this.$bizUtil.getDateToString(new Date(), '-')

    },
    
    /**
     * @description 부모화면에 전달할 리턴 데이터를 세팅해준다.
     */
    fn_SetReturnVal () {
      
      // 초기화 
      this.lv_ReturnVal = {} 

      // 조회조건 결과 설정
      this.fn_SetSelectedItem()

      // 조직정보 세팅
      this.lv_ReturnVal.cnsltEno = !_.isEmpty(this.lv_SelectOrg.cnslt)  ? this.lv_SelectOrg.cnslt.key  : ''   
      this.lv_ReturnVal.dofOrgCd = !_.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.key : '' 
      this.lv_ReturnVal.fofOrgCd = !_.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.key : '' 
      this.lv_ReturnVal.hofOrgCd = !_.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.key : '' 
      this.lv_ReturnVal.pOrgData = this.lv_SelectOrg 
      
      this.lv_ReturnVal.searchedCustNm  = this.searchedCustNm.trim()  
      this.lv_ReturnVal.custCntcGrpIdCd = this.custCntcGrpIdCd.value  
      this.lv_ReturnVal.custCntcIdCd    = this.custCntcIdCd.value     
      this.lv_ReturnVal.openCd          = this.openCd.value          
      this.lv_ReturnVal.touchCd         = this.touchCd.value          
      this.lv_ReturnVal.mktAgreeCd      = this.mktAgreeCd.value       
      this.lv_ReturnVal.chnlCustScCd    = this.chnlCustScCd.value     
      this.lv_ReturnVal.startDate       = this.startDate             
      this.lv_ReturnVal.endDate         = this.endDate                
      this.lv_ReturnVal.selectedItems   = this.selectedItems         
      this.lv_ReturnVal.isCleared       = this.isCleared              

      // 리턴값 세팅여부
      this.isReturn = true

    },

    /**
     * @description 검색결과 데이터(조건이름)을 저장한다.
     *              초기 조건값과 현재 조건값이 동일하지 않을 때만 저장한다.
     */
    fn_SetSelectedItem(){
      let defStartDate = this.$bizUtil.getFirstDayOfMonth(new Date(moment(this.today)), 'String'),
          defEndDate   =  this.$bizUtil.getDateToString(new Date(), '-')

      if(this.$bizUtil.isEmpty(this.searchedCustNm.trim()) && this.startDate === defStartDate && this.endDate === defEndDate && 
          (this.custCntcGrpIdCd.value === this.custCntcGrpIdCds[0].value) && (this.custCntcIdCd.value === this.custCntcIdCds[0].value) && (this.openCd.value === this.openCds[0].value) &&
          (this.touchCd.value === this.touchCds[0].value) && (this.mktAgreeCd.value === this.mktAgreeCds[0].value && (this.chnlCustScCd.value === this.chnlCustScCds[0].value))){
        
        // 조직 컴포넌트 데이터가 존재할 경우
        if(!_.isEmpty(this.lv_SelectOrg)) {
          if(this.userInfo.userId === this.lv_SelectOrg.cnslt.key){
            this.isCleared = true 
            return 
          } 
        }else{
          this.isCleared = true 
          return 
        }

      }

      this.isCleared = false 

      this.selectedItems.hofOrgCd.text = !this.$bizUtil.isEmpty(this.lv_SelectOrg.hofOrg) ? this.lv_SelectOrg.hofOrg.label : '' // 선택지점
      this.selectedItems.dofOrgCd.text = !this.$bizUtil.isEmpty(this.lv_SelectOrg.dofOrg) ? this.lv_SelectOrg.dofOrg.label : '' // 선택사업부
      this.selectedItems.fofOrgCd.text = !this.$bizUtil.isEmpty(this.lv_SelectOrg.fofOrg) ? this.lv_SelectOrg.fofOrg.label : '' // 선택지역단
      this.selectedItems.cnsltEno.text = !this.$bizUtil.isEmpty(this.lv_SelectOrg.cnslt) ? new Object(this.lv_SelectOrg.cnslt).label.split('(')[0].trim() : '' // 선택컨설턴트
      
      this.selectedItems.searchedCustNm.text  = this.searchedCustNm.trim() // 고객명
      this.selectedItems.custCntcGrpIdCd.text = this.custCntcGrpIdCd.text  // 대분류
      this.selectedItems.custCntcIdCd.text    = this.custCntcIdCd.text     // 중분류

      this.selectedItems.occuYmd.text         = this.prcOccuYmd            // 발생일자
      this.selectedItems.openCd.text          = this.openCd.text           // 개봉여부 
      this.selectedItems.touchCd.text         = this.touchCd.text          // 터치여부
      this.selectedItems.mktAgreeCd.text      = this.mktAgreeCd.text       // 마케팅동의
      this.selectedItems.chnlCustScCd.text    = this.chnlCustScCd.text     // 고객구분

    },

    /**
     * @description 리턴 데이터 세팅 후, 팝업을 닫는다.
     */
    fn_ClickSearch(){

      // 유효성체크
      if(!this.fn_CheckDateVal()) return 

      // 리턴값 세팅
      this.fn_SetReturnVal() 

      if (this.isReturn) {
        this.$emit('searchPopup', { rtnData: this.lv_ReturnVal});
      }

    },

    /**
     * @description 고객알람 수신설정 팝업을 제어한다.
     */
    fn_OpenMSPCM123P(){

      this.popup123 = this.$bottomModal.open(MSPCM123P,{
        properties:{
          pPage: 'MSPCM123P',
          pOrgData: this.pOrgData122P
        },

        listeners: {
          closePopup: (pData) => {
            this.$bottomModal.close(this.popup123)

            if(pData === 'update') this.fn_ClickSearch(pData)
          },
        }
      })

    }
    
 },// methods

}//export default


</script>