/*
 * 업무구분 : 고객관리
 * 화면 명 : MSPCM123P
 * 화면 설명: 고객알람 수신 설정 
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.01.31
 * 작 성 자: 서영화 
 */

<template>
  <ur-page-container class="msp" title="고객알람 수신 설정" :show-title="true" type="subpage" :topButton="true">
    <template #frame-header-fixed>
      <!-- 분류 -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-area">
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="info-box mt30 pb20">
          <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="row-box mt10">  
            <span class="label-title min100">분류</span>
            <msp-bottom-select :items="cntcInfoInqrScCds" v-model="cntcInfoInqrScCd" underline class="ns-dropdown-sheet mb10" :class="'cm123p-show-bottom-title'" placeholder="전체" bottom-title="분류" closeBtn/>
          </ur-box-container>
        </ur-box-container>
      </ur-box-container>
      <!-- 전체선택 -->
      <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isCheckAll" @click="fn_ClickCheckAll()" :disabled="isDisabledCheck || isNoData" size="small" />
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum"><strong class="crTy-blue3">{{ checkedCount }}</strong>건 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container v-if="!isNoData" alignV="start" componentid="ur_box_container_005" direction="column" class="ns-check-list type-1 pb80">
        <mo-list :list-data="cmCustCntcIdListSVO">
          <template #list-item="{ item }">
            <mo-list-item class="pb5" :class="{'on' : item.checked === true}">
              <mo-checkbox v-model="item.checked" @input="fn_ClickCheckPartial" :disabled="isDisabledCheck" class="mr10"/>
              <div class="list-item__contents">
                <div class="list-item__contents__info crTy-bk1">
                  <!-- 접촉ID -->
                  <span class="info txt-center min60 mr10"><span class="crTy-bk1">{{ item.custCntcId }}</span></span>
                  <!-- 접촉ID명 -->
                  <span class="crTy-bk1 break-word">{{ item.custCntcNm }}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
      <!-- NoData 영역  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_006" direction="column" class="ns-check-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 없습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>

      <!-- 초기화/조회 버튼 -->
      <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column" class="ns-sch-area">
        <ur-box-container alignV="start" componentid="ur_box_container_008" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_Close">취소</mo-button>
            <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue"  @click="fn_SetCmCustCntcIdList">{{ cntcInfoInqrScCd === '02'? '수신': '제외'}}</mo-button>
          </div>
        </ur-box-container>  
      </ur-box-container>

      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>

    </ur-box-container>
  </ur-page-container>
</template>

<script>

import cmConstants from '~/src/config/constants/cmConstants'

export default {

  name: 'MSPCM123P', 
  screenId: 'MSPCM123P', 
  components: {},//componets,

  props:{
    pPage: String,
    pOrgData: {
      type: Object,
      default: {}
    },
  },//props

  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },

  data () {
    return {

      lv_inputParamGet: {},      // 조회 서비스호출 파라미터
      lv_inputParamSet: {        // 수정 서비스호출 파라미터
        cmCustCntcIdListSVO: []
      },                             
      
      /**
       * 조회 변수
       */ 
      cmCustCntcIdListSVO: [],  // 고객알람 수신 설정 리스트 
      cntcInfoInqrScCds: [],    // 분류 데이터 CODE-LIST
      cntcInfoInqrScCd: '',     // 선택된 분류 데이터         

      /**
       * 체크박스 변수
       */
      isCheckAll: false,         // 전체체크 여부 
      isDisabledCheck: false,    // 체크박스 비활성화 여부 (타 컨설턴트 고객 호출 하면 체크박스 비활성화)
      selectItems: [],           // 선택된 접촉ID 리스트 

      /**
       * 화면 변수
       */ 
      isNoData: false,           // 데이터없음
      isLoading: false,

    }
  },//data

  created () {
    
    /**
     * 공통코드 설정
     */
    this.cntcInfoInqrScCds = cmConstants.CUST_CNTC_INFO_RECI_HYPT_CD
    this.cntcInfoInqrScCd  = cmConstants.CUST_CNTC_INFO_RECI_HYPT_CD[0].value

    // 고객알람 수신 설정 조회 
    this.fn_GetCmCustCntcIdList()

  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
    // type = subpage 일 경우, noheader가 추가되어서 bottom-sheet의 title이 display none이 된다.
    // 임의의 클래스(cm123p-show-bottom-title) 추가하여 title의 display를 block으로 변경시켜준다.
    // document.querySelector('.cm123p-show-bottom-title .mo-bottom-sheet__title-wrapper').style.display ='block'
    // .cm123p-show-bottom-title > .mo-bottom-sheet > .mo-bottom-sheet__wrapper > .mo-bottom-sheet__title-wrapper

  },//mounted

  watch: {
    
    /**
     * 전체 체크여부를 확인한다.
     */
    checkedCount(){
      let cnt = this.cmCustCntcIdListSVO.length 
      if(this.checkedCount == cnt && this.cmCustCntcIdListSVO.length != 0){
        this.isCheckAll  = true
      }else{
        this.isCheckAll  = false
      }
    },

    /**
     * 분류유형 변경 시, 분류유형에 맞는 데이터를 조회한다.
     */
    cntcInfoInqrScCd (value) { 
      this.fn_GetCmCustCntcIdList()
    }

  },//watch

  computed: {
    
    /**
     * 선택된 데이터 개수를 구한다.
     */
    checkedCount(){
      return this.cmCustCntcIdListSVO.filter(item => item.checked).length
    },

    /**
     * 선택된 데이터를 추출한다. 
     */
    checkedList(){
      return this.cmCustCntcIdListSVO.filter(item => item.checked)
    },

  },//computed

  methods: {

  /**
   * @description 조회에 필요한 파라미터 설정 및 초기화 후, 고객알람 수신설정 조회 서비스를 호출한다.
   */
  fn_GetCmCustCntcIdList(){

    // 초기화 
    this.selectItems.splice(0)         // 제외된(선택된) 데이터  
    this.cmCustCntcIdListSVO.splice(0) // 고객알람 수신 설정 데이터  

    // 조회(제외)조건 세팅 
    this.lv_inputParamGet.trtTypCd         = 'R'                    // 처리유형코드 R:조회 U:수정
    this.lv_inputParamGet.cntcInfoInqrScCd = this.cntcInfoInqrScCd  
    this.lv_inputParamGet.cnsltNo          = this.pOrgData.cnsltNo  
    
    // 서비스 호출 
    this.fn_GetService()

  },

  /**
   * @description 고객알람 수신 설정 조회 서비스 호출 후, 콜백 데이터를 처리한다.
   * @callback    function(response) 
   */
  fn_GetService(){
    if (this.stndKeyVal === 'end') return

    const trnstId = 'txTSSCM52S3'
    const auth    = 'S'

    let lv_Vm   = this
    let pParams = this.lv_inputParamGet
    let confirm = this.getStore('confirm')

    this.isLoading = true

    this.post(lv_Vm, pParams, trnstId, auth)
      .then(function (response) { 

        // 서버 데이터 가져오기
        if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {

          let responseList = response.body.cMCustCntcIdListSVO

          if (responseList !== null) {

            lv_Vm.selectItems.splice(0, lv_Vm.selectItems.length)
            lv_Vm.cmCustCntcIdListSVO = responseList

            // 고객알람 수신 설정 데이터 가공
            lv_Vm.cmCustCntcIdListSVO.forEach((item, index) => {

               lv_Vm.$set(lv_Vm.cmCustCntcIdListSVO[index], 'checked', false) 

               if (lv_Vm.cmCustCntcIdListSVO[index].inclYn === 'X') {
                lv_Vm.selectItems.push(lv_Vm.cmCustCntcIdListSVO[index])
                lv_Vm.cmCustCntcIdListSVO[index].checked = true 
               }

            })

            // 데이터 유무 
            if (lv_Vm.cmCustCntcIdListSVO.length === 0) {
              lv_Vm.isNoData = true    
            } else {
              lv_Vm.isNoData = false  
            }

          }else{
            lv_Vm.isNoData = true
          }

        } else {
          confirm.dispatch('ADD', response.msgComm.msgDesc)
        }

        lv_Vm.isLoading = false

      })
      .catch(function (error) {
        window.vue.error(error)
      })

  },

  /**
   * @description 조회에 필요한 파라미터 설정 및 초기화 후, 고객알람 수신설정 수정 서비스를 호출한다.
   */
  fn_SetCmCustCntcIdList(){

    // 수정(제외)조건 세팅  
    this.lv_inputParamSet.trtTypCd         = 'U'                    // 처리유형코드 R:조회 U:수정
    this.lv_inputParamSet.cntcInfoInqrScCd = this.cntcInfoInqrScCd  
    this.lv_inputParamSet.cnsltNo          = this.pOrgData.cnsltNo 

    for (let i = 0; i < this.selectItems.length; i++) {
      let obj = {}
      obj.custCntcId = this.selectItems[i].custCntcId               // 고객접촉ID ZA_CUST_CNTC_ID
      obj.custCntcNm = this.selectItems[i].custCntcNm               // 고객접촉ID명 CHAR20
      obj.inclYn     = 'X'                                          // 포함여부, 변경 Flag CHAR1
      this.lv_inputParamSet.cmCustCntcIdListSVO.push(obj)
    }

    //서비스 호출 
    this.fn_SetService()

  },

 /**
  * @description 고객알람 수신 설정 수정 서비스 호출 후, 콜백 데이터를 처리한다.
  * @callback    function(response) 
  */
  fn_SetService(){

    if (this.stndKeyVal === 'end') return

    const trnstId = 'txTSSCM52S3'
    const auth    = 'S'

    let lv_Vm   = this
    let pParams = this.lv_inputParamSet
    let confirm = this.getStore('confirm')

    this.isLoading = true

    this.post(lv_Vm, pParams, trnstId, auth)
      .then(function (response) {

        // 서버 데이터 가져오기
        if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {

          let responseList = response.body.cMCustCntcIdListSVO

          if (responseList !== null) {

            //let toastMsg = '변경사항이 저장되었습니다.'
            let toastMsg = lv_Vm.$t('cm').ECMC054
            lv_Vm.$refs.snackbar.show({ text: toastMsg })
            //lv_Vm.cmCustCntcIdListSVO.splice(0, lv_Vm.cmCustCntcIdListSVO.length)
            //lv_Vm.cntcInfoInqrScCd = cmConstants.CUST_CNTC_INFO_RECI_HYPT_CD[0].value

          }
          
          setTimeout(() => {
            // 팝업닫기 
            lv_Vm.$emit('closePopup', 'update')
          }, 700)

        } else {
          confirm.dispatch('ADD', response.msgComm.msgDesc)
        }

        lv_Vm.isLoading = false

      })
      .catch(function (error) {
        window.vue.error(error)
      })
    
    },

  /**
   * @description 조회목록을 전체선택 한다. 
   */
  fn_ClickCheckAll(){

    this.$nextTick(() =>{

      if(this.cmCustCntcIdListSVO.length > 0){
        this.selectItems.splice(0, this.selectItems.length)
        this.cmCustCntcIdListSVO.forEach((item, index) => {
          if(this.isCheckAll){
            this.cmCustCntcIdListSVO[index].checked = true
            this.selectItems.push(item)
          }else{
            this.cmCustCntcIdListSVO[index].checked = false
          }
        })
      }
    })

  },

  /**
   * @description 조회목록을 부분선택 한다.
   */
  fn_ClickCheckPartial(){

    this.selectItems = [] // 초기화
    this.selectItems = this.checkedList

  },
  
  /**
   * @description 고객알람 수신 설정 팝업을 닫는다.
   */
  fn_Close(){

    this.$emit('closePopup');
    
  }
    
 },// methods

}//export default


</script>